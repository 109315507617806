import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/Layout";

export default function BlogPost({ data }) {
  const post = data.mdx;

  return (
    <Layout>
      <h1>{post.frontmatter.title}</h1>
      <small>{post.frontmatter.date}</small>
      <MDXRenderer>{post.body}</MDXRenderer>
    </Layout>
  );
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
      }
    }
  }
`;
